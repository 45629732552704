/* You can add global styles to this file, and also import other style files */
@import "./../../shared/styles/global/styles.scss";


mdb-table-pagination
  nav
  ul.d-flex.flex-center.pagination.pagination-circle.pg-blue {
  margin: 0px !important;
}

.card-header,
.card-footer {
  font-weight: bold;
  padding-left: 1rem;
  padding-right: 1rem;
}

.card-footer {
  padding-left: 1rem;
  padding-right: 1rem;
}

.card-body {
  padding: 0px;
}

th,
td {
  font-size: medium;
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

th {
  font-weight: 600 !important;
}

/* Clickable actions in tables */
.clickable-action {
  &:hover {
    color: var(--custom-blue);
    cursor: pointer;
  }
}

.modal {
  // Fixes broken mdb-select that uses the same z-index as the modal
  z-index: 1049 !important;
}
