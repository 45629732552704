/* You can add global styles to this file, and also import other style files */
@import "./dimensions.scss";
@import "./colors.scss";
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins/_breakpoints";

:host {
  height: 100vh;
  display: block;
  overflow: hidden;
}

body {
  background-color: $background;
  // To change the font-family it is needed to be '!important', since mdbootstrap 'plans' to adjust the font-family it in the future
  // https://mdbootstrap.com/support/general/change-font-family-globally/
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
}

/* Fix for known bug */
.container-fluid,
.card-body.container-fluid {
  padding-right: 15px !important;
  padding-left: 15px !important;
  margin-right: auto !important;
  margin-left: auto !important;
}

#slide-out {
  background-color: $custom-white !important;
  width: $sidenavWidth;
}

.navbar {
  height: $navbarMasterHeight;
}

.custom-btn {
  background-color: $custom-brown !important;
  color: $custom-white !important;
  border-radius: 4px !important;
}

.custom-btn-light {
  background-color: $custom-white;
  color: $custom-brown;
  border-radius: 10px !important;
  border: 1px solid $custom-brown !important;
  height: 44px;
}

.custom-btn-deactivated {
  background-color: $custom-white !important;
  color: $custom-brown;
  border: 1px solid $custom-brown !important;
  border-radius: 10px !important;
}

.custom-btn-activated {
  background-color: $custom-blue !important;
  color: $custom-white;
  border-radius: 10px !important;
  border: 1px solid $custom-blue !important;
}

// Modal Buttons
.decline-button {
  background-color: red !important;
}

.accept-button {
  background-color: green !important;
}

.modal-content:not(.card-image) .close {
  right: 21px !important;
}

.custom-jumbotron {
  background-color: $custom-white !important;
  border: 1px solid $custom-brown;
  border-radius: 10px !important;
  color: $custom-brown;
}

.jumbotron-activated {
  background-color: $custom-blue !important;
  color: $custom-white;
  border-radius: 10px !important;
  border: 1px solid $custom-blue !important;
}

.custom-header {
  text-transform: uppercase;
  color: $custom-blue;
}

.card-header {
  background-color: white;
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.card {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  border-radius: 10px;
}

.md-form textarea {
  border-radius: 4px !important;
  border: 1px solid #ced4da;
}

.border-textarea.md-form textarea.md-textarea:not(focus) {
  border-radius: 4px !important;
  border-top: 1px solid #ced4da;
  border-right: 1px solid #ced4da;
  border-left: 1px solid #ced4da;
}

.blue-textarea textarea.md-textarea:focus:not([readonly]) {
  border: 1px solid $custom-blue;
  border-radius: 4px !important;
}

.active-blue-textarea.md-form label.active {
  color: $custom-blue;
}

html {
  font-size: 21px;
}

.modal-dialog {
  width: 100% !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.modal-dialog.w-80 {
  width: 80% !important;
}

.modal-dialog.max-width-600 {
  max-width: 600px !important;
}

.modal-dialog-max-width-700 {
  max-width: 700px !important;
}

.modal-full-height {
  position: unset !important;
  > .modal-content {
    max-height: 100svh !important;
  }
}

#participantHomeCarousel .carousel,
#participantHomeCarousel .carousel .carousel-inner,
#participantHomeCarousel .carousel .carousel-inner .carousel-item,
#participantHomeCarousel
  .carousel
  .carousel-inner
  .active
  .carousel-fade
  .slide {
  height: var(--participant-carousel-height, 100%) !important;
  display: block !important;
}

img {
  &.d-block {
    background-position: center center;
    object-fit: scale-down; // alternative cover
  }
}

.carousel-indicators {
  li {
    background-color: $custom-blue !important;
  }
}

/* Hide scrollbar for IE, Edge and Firefox */
.hideScrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Dropdown item background color if selected */
.dropdown-item.active,
.dropdown-item:active {
  color: white !important;
  background-color: var(--custom-blue) !important;
}

.btn.btn-primary {
  color: white !important;
  background-color: var(--custom-blue) !important;
}

/* Muted text */
.text-muted {
  color: grey;
  font-size: 0.7rem;
}

/* Checkbox fix */
.form-check-input[type="checkbox"]:not(:checked) + label:before,
.form-check-input[type="checkbox"]:not(:checked):not(.filled-in) + label:after,
label.btn input[type="checkbox"]:not(:checked) + label:before,
label.btn input[type="checkbox"]:not(:checked):not(.filled-in) + label:after {
  top: 50% !important;
  margin: 0 !important;
  transform: translateY(-50%) !important;
}

/* Form validation label on error */
.text-color-invalid-field {
  color: #f44336 !important;
}
/* Form validation label on success */
.text-color-valid-field {
  color: #00c851 !important;
}

/* Dropdown blue */
.blue-dropdown {
  background-color: $custom-blue !important;
  color: white !important;
  width: 200px;
}

// Ensures scrolling for each overflowing mdb modal
mdb-modal-container {
  overflow-y: scroll !important;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
}

td {
  word-break: break-word !important;
  overflow-wrap: break-word !important;
}

.label-pl-25 {
  label {
    padding-left: 25px !important;
  }
}

.modal .modal-frame {
  @media (max-width: 767px) {
    padding: 0rem !important;
  }
}

.modal-width-override {
  &.modal-dialog {
    max-width: 75%;
    @include media-breakpoint-down(sm) {
      max-width: 100%;
      // margin: 1rem;
      padding: 1rem;
    }
  }
}

.scaled-down-canvas {
  width: 100%;
  height: 100%;
  > canvas {
    width: 100% !important;
    height: 100% !important;
    object-fit: scale-down;
  }
}

// Quilljs HTML Editor settings
@import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.snow.css";
.ql-editor {
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5;
  color: #212529;
}

.quill-button-button {
  background-color: var(--custom-blue) !important;
  color: white !important;
  text-decoration: none !important;
}

.ql-tooltip {
  overflow: visible !important;
}

.table-side-borderless {
  tr > td:first-child,
  tr > th:first-child {
    border-left: none !important;
  }
  tr > td:last-child,
  tr > th:last-child {
    border-right: none !important;
  }
  > tbody > tr:last-child > td {
    border-bottom: none !important;
  }
  border-bottom: none !important;
  border-left: none !important;
  border-right: none !important;
}

mdb-select-option.multi-select:not(.selected) {
  > span {
    display: flex;
    align-items: center;
    > label {
      margin: 0 !important;
    }
    > .mdb-option-checkbox-label {
      margin: 0 !important;
    }
  }
}

mdb-navbar > nav {
  padding-left: calc(1rem + env(safe-area-inset-left)) !important;
  padding-right: calc(1rem + env(safe-area-inset-right)) !important;
}

/* Importing Bootstrap SCSS file. */
/* Should always be on the bottom of the styles.scss */
@import "~bootstrap/scss/bootstrap";
