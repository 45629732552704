$navbarMasterHeight: 60px;
$navbarManagerHeight: 60px;
$sidenavWidth: 220px;
$floatingButtonHeight: 60px;

// Allow usage of variables inside html.
:root {
  --navbarMasterHeight: #{$navbarMasterHeight};
  --navbarManagerHeight: #{$navbarManagerHeight};
  --sidenavWidth: #{$sidenavWidth};
  --floatingButtonHeight: #{$floatingButtonHeight};
}
