$custom-brown: #2b1d20;
$custom-blue: #309ed9;
$custom-white: #f3f1ee;
$background: rgb(249, 249, 248);

// Service colors
$service-outdoor-enter: rgb(102, 219, 76);
$service-outdoor-knocking: rgb(240, 141, 52);
$service-done: green;
$service-canceled: red;
$service-inprogress: green;
$service-requested: $custom-blue;

// Allow usage of variables inside html.
:root {
  --custom-brown: #{$custom-brown};
  --custom-blue: #{$custom-blue};
  --custom-white: #{$custom-white};
  --background: #{$background};
  --service-outdoor-enter: #{$service-outdoor-enter};
  --service-outdoor-knocking: #{$service-outdoor-knocking};
  --service-done: #{$service-done};
  --service-canceled: #{$service-canceled};
  --service-inprogress: #{$service-inprogress};
  --service-requested: #{$service-requested};
}
